import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import content from '../content.json'
import logo from '../images/logo.png'

const Seo = ({ description, lang, image, title, keywords, type }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: type,
        }
      ]}
    />
  )
}

Seo.defaultProps = {
  lang: 'es',
  type: 'website',
  keywords: content.seo.keywords,
  title: content.seo.title,
  description: content.seo.description,
  image: logo
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string)
}

export default Seo
