import AppBar from "@material-ui/core/AppBar"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import close from "../../images/layout/close.svg"
import logo from "../../images/logo.svg"
import open from "../../images/layout/menu.svg"
import whatsapp from "../../images/layout/whatsapp.svg"
import content from "../../content.json"
import { colors } from "../../colors"

const useStyles = makeStyles({
  appBar: {
    "@media (min-width: 940px)": {
      boxShadow: "none",
      'html:not([data-scroll="0"]) &': {
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      },
    },
  },
  toolbar: {
    minHeight: "72px",
    paddingLeft: "24px",
    paddingRight: "24px",
    justifyContent: "space-between",
    position: "relative",
    "@media (min-width: 992px)": {
      paddingLeft: "36px",
      paddingRight: "36px",
    },
    "@media (min-width: 1200px)": {
      width: "100%;",
      maxWidth: "1330px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  openBtn: {
    marginRight: "-12px",
    width: "48px",
    height: " 48px",

    "@media (min-width: 940px)": {
      display: "none",
    },
  },
  closeBtn: {
    margin: "12px 0 0 12px",
    width: "48px",
    height: "48px",
  },
})

const Brand = styled.div`
  p {
    display: none;
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    img {
      width: 24px;
      height: 24px;
    }

    a {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 1250px) {
    p {
      display: block;
      color: ${colors.text};
      margin: 0 0 0 8px;
      text-transform: uppercase;
      letter-spacing: 8px;
      font-weight: 900;
    }
  }
`

const NavLink = styled(Link)`
  font-weight: 500;
  outline: none;
  text-transform: uppercase;
  

  &:hover,
  &:focus {
    color: ${colors.boldBtn};
  }

  &.current-page {
    color: #707070;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

const NavBtn = styled(Link)`
  background: ${colors.button};
  padding: 8px 24px;
  border-radius: 16px;
  color: ${colors.text};
  font-weight: 500;
  align-self: flex-start;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media (min-width: 768px) {
    font-size: 1.12rem;
  }

  @media (min-width: 940px) {
    order: 10;
    align-self: center;
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.26);
  }
`

const WhatsappLink = styled.a`
p {
    font-size: 1.2rem;
    display: none;
  }
  @media (min-width: 940px) {
    display: flex;
    align-items: center;
    margin-left: 24px;

    p {
      display: block;
      margin-left: 8px;
      color: ${colors.lightText};
    }
  }

  @media (min-width: 1120px) {
    margin-left: 72px;
  }
`

const NavLinksContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 270px;
  padding: 24px;
  @media (min-width: 940px) {
    flex-direction: row;
  }
`

const NavLinks = ({ className }) => (
  <>
    {content.nav.links.map((link, i) =>
      i === content.nav.links.length - 1 ? (
        <NavBtn to={link.link} key={i} className={className}>
          {link.text}
        </NavBtn>
      ) : (
        <NavLink
          to={link.link}
          key={i}
          className={className}
          activeClassName="current-page"
        >
          {link.text}
        </NavLink>
      )
    )}
  </>
)

const NavLinksDesktop = styled(NavLinks)`
  display: none;

  @media (min-width: 940px) {
    display: block;
  }
`

export default function Nav() {
  const [menuOpen, setMenuOpen] = useState(false)
  const classes = useStyles({})
  const toggleMenu = () => setMenuOpen(menu => !menu)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      // listen to scroll and change nav background color
      const debounce = fn => {
        let frame
        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        }
      }
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener("scroll", debounce(storeScroll), {
        passive: true,
      })
      storeScroll()

      return () => {
        document.removeEventListener("scroll", debounce(storeScroll))
      }
    }
  }, [])

  return (
    <>
      <AppBar
        position="fixed"
        style={{ background: "white" }}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.openBtn}
            edge="start"
            aria-label="menu"
            onClick={toggleMenu}
          >
            <img src={open} alt="open menu" />
          </IconButton>

          <Brand>
            <Link to="/">
              <img src={logo} alt="logo" />
              <p>{content.nav.name}</p>
            </Link>
          </Brand>
          <NavLinksDesktop />
          <WhatsappLink href={`https://wa.me/${content.socialMedia.whatsapp}`}>
            <img
              src={whatsapp}
              alt="whatsapp"
              style={{ width: "20px", height: "20px" }}
            />
            <p>{content.socialMedia.whatsapp}</p>
          </WhatsappLink>
        </Toolbar>
        <Drawer anchor="left" open={menuOpen} onClose={toggleMenu}>
          <IconButton
            className={classes.closeBtn}
            edge="start"
            aria-label="close menu"
            onClick={toggleMenu}
          >
            <img src={close} alt={"close menu"} />
          </IconButton>
          <NavLinksContainer>
            <NavLinks />
          </NavLinksContainer>
        </Drawer>
      </AppBar>
      <Toolbar style={{ marginBottom: "16px" }} />
    </>
  )
}
