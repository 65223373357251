import React from "react"
import styled from "styled-components"
import facebookImg from "../../images/icons/facebook.svg"
import instagramImg from "../../images/icons/instagram.svg"
import linkedinImg from "../../images/icons/linkedin.svg"
import youtubeImg from "../../images/icons/youtube.svg"
import content from "../../content.json"
import { colors } from "../../colors"

const FooterSection = styled.footer`
  background: ${colors.button};
  padding: 24px;
  color: ${colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${colors.text};

  }

  div {
    display: flex;
    align-items: center;

    a {
      margin-right: 16px;

      :last-child {
        margin-right: 0;
      }

      img {
        height: 25px;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 36px;
  }
`

const {email, instagram, facebook, youtube, linkedin} = content.socialMedia;

const networks = [
  {name: 'instagram', link: instagram, img: instagramImg},
  {name: 'facebook',link: facebook, img: facebookImg},
  {name: 'youtube',link: youtube, img: youtubeImg},
  {name: 'linkedin',link: linkedin, img: linkedinImg},
];


export default function Footer() {

  return (
    <FooterSection>
      <a href={`mailto:${email}?subject=Web`}>{email}</a>
      <div style={{marginTop: 10}}>
        {networks.map(network => ( !network.link ? null:
          <a
            key={network.name}
            href={network.link}
            target="_blank"
            rel="noreferrer"
          >
            <img src={network.img} alt={network.name} />
          </a>
        ))}
      </div>
      <p>© {new Date().getFullYear()}. Con amor @saludpsic.</p>
    </FooterSection>
  )
}
