export const colors = {
  darkText: "#676767",
  text: "#7B7B7B",
  lightText: "#bfbfbf",
  pinkText: "#F5D2B8",
  button: "#C8E6E5",
  boldBtn: "#afc9c9",
  bg1: "#F9FCFC",
  bg2: "#FEF1E7",
}
