import { StylesProvider } from "@material-ui/core/styles"
import React from "react"
import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import { colors } from "../../colors"
import Footer from "./Footer"
import Nav from "./Nav"

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
  }
  body {
    color: ${colors.text};
    font-kerning: normal;
    overflow-x: hidden;
    word-wrap: break-word;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: 'Avenir Medium', sans-serif;
  } 


  p, span {
    font: 'Avenir Light', sans-serif;
  }

  button,
  label,
  input,
  select,
  textarea,
  textarea::placeholder,
  input::placeholder {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
  }

  a {
    text-decoration: none;
    color: ${colors.button};
  }

  *, ::before, ::after {
    box-sizing: border-box;
    
  }

  img {
    max-width: 100%;
    display: block;
  }
  
  
  button,
  input {
  overflow: visible;
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
  
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  @media (min-width: 600px) {

    h1 {
    font-size: 3rem;
    line-height: 133%;
    }
    h2 {
      font-size: 2.25rem;
      line-height: 134%
    }
    h3 {
      font-size: 1.75rem;
      line-height: 133%;
    }
    h4 {
      font-size: 1.5rem;
      line-height: 134%
    }
    h5 {
      font-size: 1.25rem;
      line-height: 134%;
    }
    h6 {
      font-size: 1rem;
      line-height: 137%;
    }

    a, p, span, button, input, label, div {
      font-size: 1.12rem;
    }
  }

  
  @media (min-width: 1280px) {

    h1 {
      font-size: 3rem;
    }
    
    a, p, span, button, input, label, div {
      font-size: 1.4rem;
    }
  }

  .custom-swiper-pagination {
    background: ${colors.button};
    opacity: .75;
    
    @media (min-width: 768px) {
      width: 14px;
      height: 14px;
    }
  }

  .swiper-pagination-bullet-active {
    background: ${colors.boldBtn};
    opacity: 1;
  }
  
`

export default function Layout({ children }) {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <Nav />
      <main>{children}</main>
      <Footer />
    </StylesProvider>
  )
}
